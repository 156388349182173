<script>
import layoutProfile from "@/views/layouts/layoutProfile";
import {required} from "vuelidate/lib/validators";
import {profileMethods, profileComputed,} from "@/state/helpers";
import Loader from "@/components/Partials/Loader.vue";

export default {
	name: "Identify",
	components: {
		layoutProfile,
		Loader
	},
	data() {
		return {
			canEdit: false,
			submitted: false,
			profileDetail: {
				first_name: "",
				age: "",
				city: "",
				hobby: "",
				nationality: "",
				study: "",
				occupation: "",
				personality: "",
				dream: "",
			}
		}
	},
	validations: {
		profileDetail: {
			first_name: {required},
		}
	},
	computed: {
		...profileComputed,
	},
	methods: {
		...profileMethods,
		updatePersonalInfo() {
			this.submitted = true;
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			} else {
				this.canEdit = false;
				const formData = new FormData();
				formData.append("first_name", this.profileDetail.first_name);
				formData.append("age", this.profileDetail.age || '');
				formData.append("city", this.profileDetail.city || '');
				formData.append("hobby", this.profileDetail.hobby || '');
				formData.append("nationality", this.profileDetail.nationality || '');
				formData.append("study", this.profileDetail.study || '');
				formData.append("occupation", this.profileDetail.occupation || '');
				formData.append("personality", this.profileDetail.personality || '');
				formData.append("dream", this.profileDetail.dream || '');

				this.updateUserInfo(formData)
			}
		},
		prepareData() {
			this.profileDetail.first_name = this.userInfo.first_name;
			this.profileDetail.age = this.userInfo.age;
			this.profileDetail.city = this.userInfo.city;
			this.profileDetail.hobby = this.userInfo.hobby;
			this.profileDetail.nationality = this.userInfo.nationality;
			this.profileDetail.study = this.userInfo.study;
			this.profileDetail.occupation = this.userInfo.occupation;
			this.profileDetail.personality = this.userInfo.personality;
			this.profileDetail.dream = this.userInfo.dream;
		}

	},
	async mounted() {
		await this.$store.dispatch("profile/getUserInfo");
		await this.prepareData()
	},
}
</script>

<template>
	<layoutProfile>
		<h4 class="px-4 pt-4 mb-0">Identity</h4>
		<div class="p-4 user-profile-desc" style="height: auto">
			<b-card no-body class="border custom-accordion">
				<b-card-header>
					<div v-if="!canEdit" class="float-right">
						<button
							type="button"
							style="max-width: 200px;"
							class="btn btn-primary btn-block"
							@click="canEdit = !canEdit"
						>
							<i class="ri-edit-fill mr-1 align-middle"></i>
							{{ $t("chat.tabs.settings.info.edit") }}
						</button>
					</div>
					<h5 class="font-size-15 m-0">Personal Info</h5>
				</b-card-header>
				<b-card-body>
					<Loader v-if="loading"/>
					<form v-else @submit.prevent="updatePersonalInfo">
						<b-row>
							<b-col lg="4" md="4" sm="12">
								<div class="form-group mb-4">
									<label for="updateName-input">{{
											$t("chat.tabs.settings.modal.form.name.label")
										}}</label>
									<input
										type="text"
										class="form-control"
										id="updateName-input"
										:placeholder=" $t('chat.tabs.settings.modal.form.name.placeholder')"
										v-model="profileDetail.first_name"
										:disabled="!canEdit"
										minlength="3"
										:class="{
                          'is-invalid': submitted && $v.profileDetail.first_name.$error,
                        }"
									/>
									<div
										v-if="submitted && $v.profileDetail.first_name.$error"
										class="invalid-feedback"
									>
                    <span v-if="!$v.profileDetail.first_name.required">{{
							$t("chat.tabs.settings.modal.form.name.validation.required")
						}}</span>
									</div>
								</div>
							</b-col>
							<b-col lg="4" md="4" sm="12">
								<div class="form-group mb-4">
									<label for="updateName-input">Age</label>
									<input
										type="number"
										class="form-control"
										id="updateName-input"
										placeholder="Age"
										v-model="profileDetail.age"
										:disabled="!canEdit"
										min="15"
										max="100"
									/>
								</div>
							</b-col>
							<b-col lg="4" md="4" sm="12">
								<div class="form-group mb-4">
									<label for="updateName-input">City</label>
									<input
										type="text"
										class="form-control"
										id="updateName-input"
										placeholder="City"
										v-model="profileDetail.city"
										:disabled="!canEdit"
									/>
								</div>
							</b-col>
							<b-col lg="4" md="4" sm="12">
								<div class="form-group mb-4">
									<label for="updateName-input">Nationality</label>
									<input
										type="text"
										class="form-control"
										id="updateName-input"
										placeholder="Nationality"
										v-model="profileDetail.nationality"
										:disabled="!canEdit"
									/>
								</div>
							</b-col>
							<b-col lg="4" md="4" sm="12">
								<div class="form-group mb-4">
									<label for="updateName-input">Hobby/Interest</label>
									<input
										type="text"
										class="form-control"
										id="updateName-input"
										placeholder="Hobby/Interest"
										v-model="profileDetail.hobby"
										:disabled="!canEdit"
									/>
								</div>
							</b-col>
							<b-col lg="4" md="4" sm="12">
								<div class="form-group mb-4">
									<label for="updateName-input">Personality</label>
									<input
										type="text"
										class="form-control"
										id="updateName-input"
										placeholder="Personality"
										v-model="profileDetail.personality"
										:disabled="!canEdit"
									/>
								</div>
							</b-col>
							<b-col lg="4" md="4" sm="12">
								<div class="form-group mb-4">
									<label for="updateName-input">Study</label>
									<input
										type="text"
										class="form-control"
										id="updateName-input"
										placeholder="Study"
										v-model="profileDetail.study"
										:disabled="!canEdit"
									/>
								</div>
							</b-col>
							<b-col lg="4" md="4" sm="12">
								<div class="form-group mb-4">
									<label for="updateName-input">Occupation</label>
									<input
										type="text"
										class="form-control"
										id="updateName-input"
										placeholder="Occupation"
										v-model="profileDetail.occupation"
										:disabled="!canEdit"
									/>
								</div>
							</b-col>
							<b-col lg="4" md="4" sm="12">
								<div class="form-group mb-4">
									<label for="updateName-input">Dream&Goals</label>
									<input
										type="text"
										class="form-control"
										id="updateName-input"
										placeholder="Dream&Goals"
										v-model="profileDetail.dream"
										:disabled="!canEdit"
									/>
								</div>
							</b-col>
						</b-row>
						<div>
							<button
								v-if="canEdit"
								class="w-25 btn btn-primary btn-block"
								type="submit"
							>Save
							</button>
						</div>
					</form>
				</b-card-body>
			</b-card>
		</div>
	</layoutProfile>
</template>

<style scoped lang="scss">

</style>
